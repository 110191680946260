import { Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

interface Auth0ProviderWithConfigProps {
	children: React.ReactNode;
}

export const Auth0ProviderWithHistory = ({
	children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
	const navigate = useNavigate();

	const domain = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
	const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
	const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

	const onRedirectCallback = () => {
		window.history.replaceState(null, "");
		navigate("/", { replace: true });
	};

	if (!(domain && clientId && redirectUri && audience)) {
		return null;
	}

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			authorizationParams={{
				audience: audience,
				redirect_uri: redirectUri,
			}}
			onRedirectCallback={onRedirectCallback}
			useRefreshTokens={true}
  			cacheLocation="localstorage"
		>
			{children}
		</Auth0Provider>
	);
};
