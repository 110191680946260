import React from 'react';
import { NavLink } from 'react-router-dom';

export const NavBarBrand: React.FC = () => {
	var headerTitle = 'Signal Sentry';
	var subHeaderTitle = 'JDKonsult';
	const loadingImg = '/signal_icon.png';

	return (
		<div className="nav-bar__brand">
			<div>
				<NavLink to="/" aria-label={headerTitle}>
					<div className="nav-bar__brand-content">
						<div className="nav-bar__logo-container">
							<img 
								src={loadingImg} 
								alt="Logo" 
								className="nav-bar__logo" 
								loading="eager"
								width={40}
								height={40}
							/>
						</div>
						{typeof headerTitle === 'string' ? (
							<div className="header-title">
								{headerTitle} <br />
								<span>by {subHeaderTitle}</span>
							</div>
						) : (
							headerTitle
						)}
					</div>
				</NavLink>
			</div>
		</div>
	);
};
