import React, { useState } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    flexRender,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';

type TanStackTableProps<T> = {
    data: T[];
    columns: ColumnDef<T, any>[];
    onRowClick?: (row: T) => void;
};

const getClassForValue = (value: number) => {
    if (value < 30) {
        return 'red-metric';
    } else if (value >= 30 && value < 60) {
        return 'orange-metric';
    } else if (value >= 60) {
        return 'green-metric';
    }
    return '';
};

const getClassForRating = (value: number) => {
    if (value < 2) {
        return 'red-metric';
    } else if (value >= 2 && value < 4) {
        return 'orange-metric';
    } else if (value >= 4) {
        return 'green-metric';
    }
    return '';
};

// Special handling for ML prediction which is in decimal form (0-1)
const getClassForMLPrediction = (value: number) => {
    const percentage = value * 100;
    if (percentage < 30) {
        return 'red-metric';
    } else if (percentage >= 30 && percentage < 60) {
        return 'orange-metric';
    } else if (percentage >= 60) {
        return 'green-metric';
    }
    return '';
};

export const GenericTanStackTable = <T,>({ data, columns, onRowClick }: TanStackTableProps<T>) => {
    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
        data,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const metricColorMap: Record<string, (value: number) => string> = {
        'insiderFilingScore': getClassForValue,
        'trendAdjustedScore': getClassForValue,
        'financialStrengthPercentage': getClassForValue,
        'scoreScaled': getClassForValue,
        'ratingScore': getClassForRating,
        'mlPriceIncreaseProbability': getClassForMLPrediction,
    };

    return (
        <div className="tanstack-table-wrapper">
            <div className="tanstack-table-container">
                <table className="tanstack-table">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className="sticky"
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: ' 🔼',
                                            desc: ' 🔽',
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr key={row.id} onClick={() => onRowClick?.(row.original)}>
                                {row.getVisibleCells().map((cell) => {
                                    const cellValue = cell.getValue();
                                    const accessorKey = cell.column.id;
                                    
                                    // Get the appropriate color function based on the column
                                    const getColorClass = metricColorMap[accessorKey];
                                    const cellClass = typeof cellValue === 'number' && getColorClass 
                                        ? getColorClass(cellValue) 
                                        : '';
                                    
                                    return (
                                        <td key={cell.id} className={cellClass}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};