import axios, { AxiosRequestConfig } from 'axios';
import { error } from 'console';
import { ApiResponse, NewsletterControllerApiResponse } from 'src/models/api-response';
import { postNewsLetterRequest } from './external-api.service';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
export const createNewsletterSignup = async (
    sessionData: Record<string, string>
): Promise<ApiResponse<NewsletterControllerApiResponse>> => {
    const params = new URLSearchParams(sessionData);
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/utilities/newsletter/signup`,
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
        },
        data: params.toString(),
    };

    const { data, error } = (await postNewsLetterRequest({
            config,
        })) as NewsletterControllerApiResponse;
        
    return {
        data,
        error,
    };
};

export const confirmNewsletterSignup = async (
    sessionData: Record<string, string>
): Promise<any> => {
    const params = new URLSearchParams(sessionData);
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/utilities/newsletter/confirmSubscription`,
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
        },
        data: params.toString(),
    };

    const { data, error } = (await postNewsLetterRequest({
        config,
    })) as NewsletterControllerApiResponse;
    
    return {
        data,
        error,
    };
};

export const unsubscribeUser = async (
    sessionData: Record<string, string>
): Promise<any> => {
    const params = new URLSearchParams(sessionData);
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/utilities/newsletter/unsubscribe`,
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
        },
        data: params.toString(),
    };

    const { data, error } = (await postNewsLetterRequest({
        config,
    })) as NewsletterControllerApiResponse;
    
    return {
        data,
        error,
    };
};