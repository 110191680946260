import React from 'react';
import { PageLayout } from '../../components/PageLayout';

export const StockAnalysisPage: React.FC = () => {
    return (
        <PageLayout>
            <div className="content-layout">
                <h1 id="page-title" className="content__title">
                    Advanced Stock Analysis
                </h1>
                <div className="content__body">
                    <section className="feature-section">
                        <h2>Comprehensive Stock Analysis Tools</h2>
                        <p>
                            Our advanced stock analysis platform provides powerful tools and insights to help you make 
                            data-driven investment decisions. We combine multiple data sources and analytical methods 
                            to give you a complete picture of a stock's performance and potential.
                        </p>
                    </section>

                    <section className="feature-section">
                        <h2>Analysis Features</h2>
                        <ul>
                            <li>Technical analysis indicators and charts</li>
                            <li>Fundamental analysis metrics</li>
                            <li>Market sentiment analysis</li>
                            <li>Competitive analysis</li>
                            <li>Industry benchmarking</li>
                        </ul>
                    </section>

                    <section className="feature-section">
                        <h2>AI-Powered Insights</h2>
                        <p>
                            Our platform leverages artificial intelligence to analyze vast amounts of market data and 
                            generate actionable insights. This includes:
                        </p>
                        <ul>
                            <li>Pattern recognition in price movements</li>
                            <li>Sentiment analysis of news and social media</li>
                            <li>Predictive analytics for price trends</li>
                            <li>Risk assessment and scoring</li>
                        </ul>
                    </section>

                    <section className="feature-section">
                        <h2>Investment Tools</h2>
                        <ul>
                            <li>Portfolio optimization recommendations</li>
                            <li>Risk management tools</li>
                            <li>Custom screening and filtering</li>
                            <li>Export capabilities for further analysis</li>
                        </ul>
                    </section>
                </div>
            </div>
        </PageLayout>
    );
};

export default StockAnalysisPage; 