import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../components/PageLayout';
import { getStockSummary, getTopFilings } from '../services/stock.service';
import LoadingSpinner from 'src/components/Loading/LoadingSpinner';
import { StockFilingCard } from 'src/components/StockFilings/StockFilingCard';
import { useStockContext } from 'src/context/StockContext/StockContext';
import { FinancialSummary } from 'src/models/stock-types';
import LoadingDotText from 'src/components/Loading/LoadingDotsText';
import GenericErrorMessage from 'src/components/Error/GenericErrorMessage';

export const StockFilingsPage: React.FC = () => {
    const { stocks, setStocks } = useStockContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [cardsLoading, setCardsLoading] = useState<number>(0);
    const [financialSummaries, setFinancialSummaries] = useState<Record<string, FinancialSummary>>({});
    const { getAccessTokenSilently } = useAuth0();

    const fetchData = async () => {
        const accessToken = await getAccessTokenSilently();
        setIsLoading(true);
        setError(null);
        setStocks([]);

        const { data, error } = await getTopFilings(accessToken);
        if (data) {
            setStocks(data);
            setCardsLoading(data.length);
            setIsLoading(false);
        }

        if (error) {
            setError(error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [getAccessTokenSilently]);
    return (
        <PageLayout>
            <div className="stock-filings">
                {error && <GenericErrorMessage message={error} onClose={() => setError(null)} />}
                {/* {!isLoading && (
                <div>
                    <h2 className="stock-filings__title">Recent Insider Filings <p className='hero-banner__headline__3'>A curated list of the best insider transactions over the previous 14 days</p></h2>
                </div>
                )} */}
                {isLoading ? (
                    <div>
                        {/* <div>
                            <h3 className="yearly-scores-title">
                            <LoadingDotText text={`Retrieving Recent Filings`} />
                            </h3>
                        </div> */}
                        <LoadingSpinner message='' />
                    </div>
                ) : (
                <div className="stockFilingCard-wrapper">
                    {stocks.map((stock, index) => (
                        <StockFilingCard
                            key={stock.ticker}
                            position={index + 1}
                            {...stock}
                            href={`/stockDetails/${stock.ticker}/financialData`}/>
                    ))}
                </div>
                )}  
            </div>
        </PageLayout>
    );
};
