import React from 'react';

interface SubscriptionPlanProps {
    planName: string;
    price: string;
    productId: string;
    description: string;
    onSelect: (productId: string) => void;
    isSelected: boolean;
}

export const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({
    planName,
    price,
    productId,
    description,
    onSelect,
    isSelected
}) => {
    const features = description.split('. ').filter(Boolean);
    const priceNumber = price.replace('$', '');

    return (
        <div 
            className={`subscription-plan ${isSelected ? 'selected' : ''}`}
            onClick={() => onSelect(productId)}
        >
            <h3>{planName}</h3>
            <h5>
                ${priceNumber}
                <span>/ month</span>
            </h5>
            <ul>
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
        </div>
    );
};

export default SubscriptionPlan;