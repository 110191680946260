import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageLoader } from './components/Loading/PageLoader';
import { StockDetailsPage } from './pages/stock-details-page';
import { CallbackPage } from './pages/callback-page';
import { HomePage } from './pages/home-page';
import { NotFoundPage } from './pages/not-found-page';
import { ProfilePage } from './pages/profile-page';
import { StockFilingsPage } from './pages/stock-filings-page';
import { StockPage } from './pages/stock-page';
import { AuthenticationGuard } from './components/AuthenticationGuard';
import { StockSelectPage } from './pages/stock-select-page';
import { ThemeProvider } from 'next-themes';
import { StockProvider } from './context/StockContext/StockContext';
import Head from './components/Head';
import SubscriptionPage from './pages/subscription-page';
import { SuccessDisplay } from './components/Payment/SuccessDisplay';
import ProductDisplay from './components/Payment/ProductDisplay';
import { StockTopRankedPage } from './pages/stock-top-ranked-page';
import NewsletterSignupPage from './pages/Newsletter/newsletter-signup-page';
import { NewsletterCallbackSignupPage } from './pages/Newsletter/newsletter-callback-signup';
import {NewsletterUnsubscribePage} from './pages/Newsletter/newsletter-unsubscribe-page';
import InsiderTradingPage from './pages/learn-more/insider-trading-page';
import StockAnalysisPage from './pages/learn-more/stock-analysis-page';
import AlertsPage from './pages/learn-more/alerts-page';
import ApiAccessPage from './pages/learn-more/api-access-page';

export const App: React.FC = () => {
	const { isLoading } = useAuth0();

	if (isLoading) {
		return (
			<div className="page-layout">
				<PageLoader />
			</div>
		);
	}

	return (
		<div>
			<StockProvider>
				<Head>
					{/* <ThemeProvider> */}
					<Routes>
						<Route
							path="/"
							element={<HomePage/>}
						/>
						<Route
							path="/newsletter-signup"
							element={<NewsletterSignupPage/>}
						/>
						<Route
							path="/callback/newsletterSignup/:email"
							element={<NewsletterCallbackSignupPage/>}
						/>
						<Route
							path="/callback/newsletterUnsubscribe/:email"
							element={<NewsletterUnsubscribePage/>}
						/>
						<Route
							path="/profile"
							element={<AuthenticationGuard component={ProfilePage} />}
						/>
						<Route
							path="/stockFilings"
							element={<AuthenticationGuard component={StockFilingsPage} />}
						/>
						<Route
							path="/stockDetails"
							element={<AuthenticationGuard component={StockSelectPage} />}
						/>
						<Route
							path="/stockDetails/:stockTicker/*"
							element={<AuthenticationGuard component={StockPage} />}
						/>
						<Route
							path="/stockDetails/:stockTicker/details"
							element={<AuthenticationGuard component={StockDetailsPage} />}
						/>
						<Route
							path="/stockDetails/topRankedStocks"
							element={<AuthenticationGuard component={StockTopRankedPage} />}
						/>
						<Route
							path="/subscription"
							element={<AuthenticationGuard component={SubscriptionPage} />}
						/>
						<Route
							path="/paymentCallback/success"
							element={<AuthenticationGuard component={SubscriptionPage} />}
						/>
						<Route path="/callback" element={<CallbackPage />} />
						{/* Learn More Pages */}
						<Route
							path="/learn-more/insider-trading"
							element={<InsiderTradingPage />}
						/>
						<Route
							path="/learn-more/stock-analysis"
							element={<StockAnalysisPage />}
						/>
						<Route
							path="/learn-more/alerts"
							element={<AlertsPage />}
						/>
						<Route
							path="/learn-more/api-access"
							element={<ApiAccessPage />}
						/>
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
					{/* </ThemeProvider> */}
				</Head>
			</StockProvider>
		</div>
	);
};
