import React from 'react';
import { PageLayout } from '../../components/PageLayout';

export const InsiderTradingPage: React.FC = () => {
    return (
        <PageLayout>
            <div className="content-layout">
                <h1 id="page-title" className="content__title">
                    Real-Time Insider Trading Insights
                </h1>
                <div className="content__body">
                    <section className="feature-section">
                        <h2>What are Insider Trading Signals?</h2>
                        <p>
                            Insider trading signals are valuable indicators that show when company executives, directors, 
                            or major shareholders are buying or selling their company's stock. These transactions can 
                            provide important insights into a company's future prospects.
                        </p>
                    </section>

                    <section className="feature-section">
                        <h2>Key Features</h2>
                        <ul>
                            <li>Real-time monitoring of insider transactions</li>
                            <li>Analysis of transaction patterns and volumes</li>
                            <li>Historical insider trading data</li>
                            <li>Alerts for significant insider movements</li>
                        </ul>
                    </section>

                    <section className="feature-section">
                        <h2>How It Works</h2>
                        <p>
                            Our system continuously monitors SEC filings and other regulatory sources to track insider 
                            trading activities. We analyze the data to identify patterns and generate insights that can 
                            help you make informed investment decisions.
                        </p>
                    </section>

                    <section className="feature-section">
                        <h2>Benefits</h2>
                        <ul>
                            <li>Early detection of potential market movements</li>
                            <li>Access to executive confidence indicators</li>
                            <li>Comprehensive transaction history</li>
                            <li>Customizable alerts for your portfolio</li>
                        </ul>
                    </section>
                </div>
            </div>
        </PageLayout>
    );
};

export default InsiderTradingPage; 