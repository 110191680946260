import React from 'react';

const dividerStyle: React.CSSProperties = {
    border: 'none',
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
    margin: '48px auto',
    maxWidth: '60vw',
};

const Divider: React.FC = () => {
    return <hr style={dividerStyle} />;
};

export default Divider;