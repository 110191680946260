import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

export const ProfileMenu: React.FC = () => {
    const { user, logout } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    return (
        <div className="profile-menu" ref={menuRef}>
            <button 
                className="profile-menu__trigger"
                onClick={() => setIsOpen(!isOpen)}
            >
                {user?.picture ? (
                    <img 
                        src={user.picture} 
                        alt="Profile" 
                        className="profile-menu__avatar"
                    />
                ) : (
                    <div className="profile-menu__avatar-placeholder">
                        {user?.name?.[0] || 'U'}
                    </div>
                )}
            </button>

            <div className={`profile-menu__dropdown ${isOpen ? 'open' : ''}`}>
                <div className="profile-menu__user-info">
                    <div className="profile-menu__name">{user?.name}</div>
                    <div className="profile-menu__email">{user?.email}</div>
                </div>
                <Link to="/profile" className="profile-menu__item">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z" fill="currentColor"/>
                    </svg>
                    Profile
                </Link>
                <Link to="/subscription" className="profile-menu__item">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 4H2C1.45 4 1 4.45 1 5V11C1 11.55 1.45 12 2 12H14C14.55 12 15 11.55 15 11V5C15 4.45 14.55 4 14 4ZM14 11H2V5H14V11ZM3 6H5V10H3V6ZM6 6H8V10H6V6ZM9 6H11V10H9V6Z" fill="currentColor"/>
                    </svg>
                    Subscription
                </Link>
                <button className="profile-menu__item profile-menu__item--logout" onClick={handleLogout}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 12H3V4H6V2H3C1.9 2 1 2.9 1 4V12C1 13.1 1.9 14 3 14H6V12ZM12.3 8.7L9.7 11.3L8.3 9.9L10.2 8H7V6H10.2L8.3 4.1L9.7 2.7L12.3 5.3C12.7 5.7 12.7 6.3 12.3 6.7L12.3 8.7Z" fill="currentColor"/>
                    </svg>
                    Logout
                </button>
            </div>
        </div>
    );
}; 