import React from 'react';

interface FilterComponentProps {
    onFilterChange: (filter: string) => void;
    currentFilter: string;
}

const SelectFilterComponent: React.FC<FilterComponentProps> = ({ onFilterChange, currentFilter }) => {

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedFilter = event.target.value;
        onFilterChange(selectedFilter);
    };

    return (
        <div className="filter-container">
            <p>Filter</p>
            <select className="filter-select" value={currentFilter} onChange={handleSelectChange}>
                <option value="filingScore">Filing Score</option>
                <option value="jdkScore">JDK Score</option>
                <option value="fmpFinancialStrength">FMP Financial Strength</option>
                <option value="fmpScore">FMP Score</option>
                <option value="mlScore">ML Probability Score</option>
            </select>
        </div>
    );
};

export default SelectFilterComponent;