import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api-response";
import { fetchApiDataFlatJson } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export type SubscriptionPlan = 'none' | 'starter' | 'pro' | 'premium';
export type SubscriptionStatus = 'active' | 'inactive' | 'canceled';

export interface UserDetails {
    id: number;
    providerUserId: string;
    provider: string;
    issuerType: string;
    email: string;
    username: string;
    newsletterSubscribed: boolean;
    subscriptionPlan: SubscriptionPlan;
    subscriptionStatus: SubscriptionStatus;
    createdAt: string;
}

export const getUserDetails = async (
    accessToken: string,
    userId: string
): Promise<ApiResponse<UserDetails>> => {
    // Extract the numerical ID from the Auth0 sub value (e.g., "google-oauth2|123456789" -> "123456789")
    const numericalId = userId.split('|')[1] || userId;
    
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/User/${numericalId}/details`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    return fetchApiDataFlatJson<UserDetails>({ config });
}; 