import React, { useEffect } from 'react';

interface GenericErrorMessageProps {
  message: string;
  onClose: () => void;
}

const GenericErrorMessage: React.FC<GenericErrorMessageProps> = ({ message, onClose }) => {
  const isTooManyRequests = message === "Too Many Requests";

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      onClose();
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onClose]);

  return (
    <div className="modal" onClick={onClose}>
      <div className="error-message-container" onClick={(e) => e.stopPropagation()}>
        <div className="error-icon">⚠️</div>
        <div className="error-text">{message}</div>
        <div className="error-text-tip">
          {isTooManyRequests ? "You have made too many requests. Please wait a while before trying again." : "Please try reloading the page."}
        </div>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default GenericErrorMessage;