import React from 'react';
import { PageLayout } from '../../components/PageLayout';

export const AlertsPage: React.FC = () => {
    return (
        <PageLayout>
            <div className="content-layout">
                <h1 id="page-title" className="content__title">
                    Custom Alerts and Notifications
                </h1>
                <div className="content__body">
                    <section className="feature-section">
                        <h2>Stay Informed with Smart Alerts</h2>
                        <p>
                            Never miss important market movements or opportunities with our customizable alert system. 
                            Get notified about the events that matter most to your investment strategy.
                        </p>
                    </section>

                    <section className="feature-section">
                        <h2>Alert Types</h2>
                        <ul>
                            <li>Price movement alerts</li>
                            <li>Volume spike notifications</li>
                            <li>Technical indicator triggers</li>
                            <li>News and event alerts</li>
                            <li>Insider trading notifications</li>
                            <li>Earnings report reminders</li>
                        </ul>
                    </section>

                    <section className="feature-section">
                        <h2>Customization Options</h2>
                        <p>
                            Tailor your alerts to match your investment style:
                        </p>
                        <ul>
                            <li>Set custom price thresholds</li>
                            <li>Choose notification frequency</li>
                            <li>Select preferred delivery methods</li>
                            <li>Create portfolio-specific alerts</li>
                        </ul>
                    </section>

                    <section className="feature-section">
                        <h2>Notification Channels</h2>
                        <ul>
                            <li>Email notifications</li>
                            <li>Mobile push alerts</li>
                            <li>Browser notifications</li>
                            <li>In-app notifications</li>
                        </ul>
                    </section>

                    <section className="feature-section">
                        <h2>Smart Features</h2>
                        <ul>
                            <li>AI-powered alert recommendations</li>
                            <li>Alert performance tracking</li>
                            <li>Batch alert management</li>
                            <li>Alert history and analytics</li>
                        </ul>
                    </section>
                </div>
            </div>
        </PageLayout>
    );
};

export default AlertsPage; 