import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import { getStockDailyClosePrice } from '../../../services/stock.service';
import { StockCompanyDetails, StockDailyClosePrice } from 'src/models/stock-types';
import LoadingDotText from 'src/components/Loading/LoadingDotsText';
import { getStockCompanyDetails } from 'src/services/stock.service';

interface StockPageHeaderProps {
	ticker: string;
}

const StockPageHeader: React.FC<StockPageHeaderProps> = ({ ticker }) => {
	// const [dailyClosePrice, setDailyClosePrice] =
	// 	useState<StockDailyClosePrice | null>(null);
	const [companyDetails, setCompanyDetails] = useState<StockCompanyDetails | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				setError(null);
				const accessToken = await getAccessTokenSilently();
				const { data, error } = await getStockCompanyDetails(
					accessToken,
					ticker
				);

				if (error) {
					setError(error.message);
				} else {
					setCompanyDetails(data);
				}
			} catch (err) {
				setError('An unexpected error occurred');
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [ticker, getAccessTokenSilently]);

	// const formattedPrice = dailyClosePrice
	// 	? new Intl.NumberFormat('en-US', {
	// 			style: 'currency',
	// 			currency: 'USD',
	// 	  }).format(Number(dailyClosePrice.closePrice))
	// 	: '';

	// const formattedDate = dailyClosePrice
	// 	? new Intl.DateTimeFormat('en-US', {
	// 			year: 'numeric',
	// 			month: 'numeric',
	// 			day: 'numeric',
	// 	  }).format(new Date(dailyClosePrice.date))
	// 	: '';

	return (
		<div className="stock-header">
			<p className="stock-ticker">{ticker}</p>
			{isLoading ? (
				<div className="skeleton-text">
					<div className="skeleton-line"></div>
				</div>
			) : companyDetails ? (
				<p className="company-details">{companyDetails.companyName + " (" + companyDetails.industry + ")"}</p>
			) : null}
			{/* {isLoading && <LoadingDotText text="" />}
			{error && <p className="error-message">X</p>} */}
			{/* {!isLoading && !error && dailyClosePrice && (
				<p className="stock-price">
					{formattedPrice} - <span className="stock-date">{formattedDate}</span>
				</p>
			)} */}
		</div>
	);
};

export default StockPageHeader;
