import React, { useEffect, useState } from 'react';
import { PageLayout } from '../components/PageLayout';
import { GenericTanStackTable } from '../components/Table/GenericTable';
import { ColumnDef } from '@tanstack/react-table';
import { StockData } from 'src/models/stock-types';
import { getStockData } from 'src/services/stock.service';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingDotText from 'src/components/Loading/LoadingDotsText';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'src/components/Loading/LoadingSpinner';
import GenericErrorMessage from 'src/components/Error/GenericErrorMessage';
import SelectFilterComponent from 'src/components/Filtering/SelectFilteringComponent';
import useWindowSize from 'src/helpers/UseWindowSize';

export const StockTopRankedPage: React.FC = () => {
	const [stockData, setStockData] = useState<StockData[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [selectedFilter, setSelectedFilter] = useState<string>('null');

	const { width } = useWindowSize();

	const { getAccessTokenSilently } = useAuth0();
	const navigate = useNavigate();

	const handleFilterChange = (filter: string) => {
		setSelectedFilter(filter);
	};

	const handleRowClick = (ticker: string) => {
		navigate(`/stockDetails/${ticker}/financialData`);
	};

	useEffect(() => {
		const fetchStockData = async () => {
			const accessToken = await getAccessTokenSilently();
			setIsLoading(true);
			setError(null);

			const { data, error } = await getStockData(
				accessToken,
				100,
				selectedFilter
			);

			if (data) {
				const dataWithRanking = data?.map((item: StockData, index: number) => ({
					ranking: index + 1,
					...item,
				}));
				setStockData(dataWithRanking || []);
				setIsLoading(false);
			}
			if (error) {
				setError(error.message);
				setIsLoading(false);
			}
		};

		fetchStockData();
	}, [selectedFilter, getAccessTokenSilently]);

	const allColumns: ColumnDef<StockData, any>[] = [
		{
			accessorKey: 'ranking',
			header: 'Ranking',
		},
		{ accessorKey: 'ticker', header: 'Ticker' },
		{ accessorKey: 'companyName', header: 'Company Name' },
		{
			accessorKey: 'insiderFilingScore',
			header: 'Filing Score',
			cell: (info) => `${info.getValue()?.toFixed(0) ?? 'N/A'}%`,
		},
		{
			accessorKey: 'trendAdjustedScore',
			header: 'JDK Score',
			cell: (info) => `${info.getValue()?.toFixed(0) ?? 'N/A'}%`,
		},
		{
			accessorKey: 'financialStrengthPercentage',
			header: 'Financial Stability',
			cell: (info) => `${info.getValue()?.toFixed(0) ?? 'N/A'}%`,
		},
		{
			accessorKey: 'scoreScaled',
			header: 'Buy Timing',
			cell: (info) => `${info.getValue()?.toFixed(0) ?? 'N/A'}%`,
		},
		{
			accessorKey: 'ratingScore',
			header: 'FMP Score',
		},
		{
			accessorKey: 'mlPriceIncreaseProbability',
			header: 'ML Prediction',
			cell: (info) => {
				const value = info.getValue() as number;
				return value !== undefined && value !== null
					? `${(value * 100).toFixed(0)}%`
					: 'N/A';
			},
		},
		// {
		//     accessorKey: 'rating',
		//     header: 'Rating',
		// },
		// {
		//     accessorKey: 'recommendation',
		//     header: 'Recommendation',
		// },
		{
			accessorKey: 'tradeDate',
			header: 'Trade Date',
			cell: (info) => new Date(info.getValue() as string).toLocaleDateString(),
		},
		{
			accessorKey: 'numberOfInsiderTraders',
			header: 'Insider Traders',
		},
		{
			accessorKey: 'purchaseTotal',
			header: 'Purchase Total',
			cell: (info) =>
				`$${(info.getValue() as number)?.toLocaleString() ?? 'N/A'}`,
		},
		// {
		//     accessorKey: 'purchasePrice',
		//     header: 'Purchase Price',
		//     cell: (info) => `$${(info.getValue() as number).toFixed(0)}`,
		// },
		// { accessorKey: 'quantity', header: 'Quantity' },
		// {
		//     accessorKey: 'previouslyOwned',
		//     header: 'Previously Owned',
		// },
		{
			accessorKey: 'percentageOwned',
			header: 'Percentage Owned',
			cell: (info) => `${info.getValue() ?? 'N/A'}%`,
		},
	];
    const mobileColumns = allColumns.slice(1, 9);
    const columns = width < 768 ? mobileColumns : allColumns;

	return (
		<PageLayout>
			{error && (
				<GenericErrorMessage message={error} onClose={() => setError(null)} />
			)}
			<div className="top_ranked_content-layout">
				{error && <p className="error-message">{error}</p>}
				{isLoading ? (
					<div>
						{/* <div>
							<h3 className="yearly-scores-title">
								<LoadingDotText text={`Calculating Top Filings`} />
							</h3>
						</div> */}
						<LoadingSpinner message={``} />
					</div>
				) : (
					<div>
						{/* <h2 className="stock-filings__title">
							Top Insider Filings{' '}
							<p className="hero-banner__headline__3">
								Discover the best-performing stocks ranked by insider trading
								activity and algorithmic analysis of company finances
							</p>
						</h2> */}
						{/* <div className="select-container">
                            <div className="fetch-button-container">
                                <button className="button" onClick={fetchData}>
                                    Get Filings
                                </button>
                            </div>
                        </div> */}
						<SelectFilterComponent
							onFilterChange={handleFilterChange}
							currentFilter={selectedFilter}
						/>
						<GenericTanStackTable
							data={stockData}
							columns={columns}
							onRowClick={(row) => handleRowClick(row.ticker)}
						/>
					</div>
				)}
			</div>
		</PageLayout>
	);
};
