import React, { useState } from 'react';
import { PageLayout } from '../components/PageLayout';
import { FaSearch, FaBell, FaCode, FaTrafficLight, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const HomePage: React.FC = () => {
    const [userSuppliedTicker, setUserSuppliedTicker] = useState('');
    const navigate = useNavigate();
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserSuppliedTicker(e.target.value.toUpperCase());
    };

    const handleSearch = () => {
        if (userSuppliedTicker) {
            navigate(`/stockDetails/${userSuppliedTicker}/financialData`);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleLogin = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        await loginWithRedirect({
            appState: {
                returnTo: '/',
            },
            authorizationParams: {
                prompt: 'login',
            },
        });
    };

    return (
        <PageLayout>
            <div className="home-page">
                <div className="hero-section">
                    <div className="hero-content">
                        <h1 className="hero-title">
                        <span className="gradient-text">Intelligent Stock Analysis</span>
                        </h1>
                        <h2 className="hero-subtitle">
                            for <span className="gradient-text2">Smart Investors</span>
                        </h2>
                        <p className="hero-description">
                            Leverage AI-powered insights to make data-driven investment decisions based on signals in the market
                        </p>
                    </div>
                    
                    
                        {isAuthenticated ? (
                            <div className="search-bar-container">
                                <div className="search-wrapper">
                                    <input
                                        type="text"
                                        value={userSuppliedTicker}
                                        onChange={handleInputChange}
                                        onKeyPress={handleKeyPress}
                                        placeholder="Analyze a stock symbol (e.g AAPL, TSLA,)"
                                        className="search-input"
                                    />
                                    <button onClick={handleSearch} className="search-button">
                                        <FaSearch />
                                    </button>
                                </div>
                            </div>    
                        ) : (
                            <div className="cta-section">
                                <div className="cta-buttons">
                                    <button onClick={handleLogin} className="primary-button">Get Started</button>
                                    <a href="/newsletter-signup" className="secondary-button">Learn More</a>
                                </div>
                            </div>
                        )}
                    </div>
                <div className="features-section">
                    <div className="features-grid">
                        <div className="feature-card">
                            <div className="feature-icon">
                                <FaTrafficLight />
                            </div>
                            <h3>Real-Time Insider Trading Insights</h3>
                            <p>Access up-to-date insider trading data to spot potential trends and valuation shifts before they impact the market.</p>
                            <a href="/learn-more/insider-trading" className="learn-more">Learn more →</a>
                        </div>

                        <div className="feature-card">
                            <div className="feature-icon">
                                <FaChartLine />
                            </div>
                            <h3>Advanced Stock Analysis</h3>
                            <p>Leverage algorithm-driven insights on financial health, investment indicators, and stock purchase recommendations.</p>
                            <a href="/learn-more/stock-analysis" className="learn-more">Learn more →</a>
                        </div>

                        <div className="feature-card">
                            <div className="feature-icon">
                                <FaBell />
                            </div>
                            <h3>Custom Alerts and Notifications</h3>
                            <p>Receive personalized updates on key investment opportunities straight to your inbox.</p>
                            <a href="/learn-more/alerts" className="learn-more">Learn more →</a>
                        </div>

                        <div className="feature-card">
                            <div className="feature-icon">
                                <FaCode />
                            </div>
                            <h3>Custom API Access</h3>
                            <p>Access our API's directly for automated data retrieval. Only for customers with premium plan.</p>
                            <a href="/learn-more/api-access" className="learn-more">Learn more →</a>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

export default HomePage;