import React from 'react';
import { FinancialSummary } from '../../../models/stock-types';
import StockExplanation from './FinancialIndicatorExplanation';

interface StockFinancialSummaryProps {
    financialSummary: FinancialSummary;
}

export const getClassForValue = (value: number, max: number = 100) => {
    const percentage = (value / max) * 100;
    if (percentage >= 0 && percentage < 30 || percentage < 0) {
        return 'red-metric spacing';
    } else if (percentage >= 30 && percentage < 60) {
        return 'orange-metric spacing';
    } else if (percentage >= 60 && percentage <= 100) {
        return 'green-metric spacing';
    }
    return '';
};

export const StockFinancialSummary: React.FC<StockFinancialSummaryProps> = ({ financialSummary }) => {
    const {
        trendAdjustedScore,
        ratingScore,
        financialStrengthPercentage,
        ratingRecommendation,
        purchaseAnalysis,
        overallScore
    } = financialSummary;

    return (
        <div>
            <div className="overall-score">
                <h2 className="overall-score__title">Overall Score</h2>
                <div className="overall-score__value">{overallScore ? Math.round(overallScore) : 0}%</div>
            </div>

            <div className="metrics-grid">
                <div className="metric-card">
                    <div className="metric-card__label">JDK Score</div>
                    <div className={`metric-card__value ${trendAdjustedScore >= 60 ? 'green' : 'red'}`}>
                        {Math.round(trendAdjustedScore)}%
                    </div>
                </div>

                <div className="metric-card">
                    <div className="metric-card__label">FMP Score</div>
                    <div className={`metric-card__value ${(ratingScore / 5) * 100 >= 60 ? 'green' : 'red'}`}>
                        {Math.round((ratingScore / 5) * 100)}%
                    </div>
                </div>

                <div className="metric-card">
                    <div className="metric-card__label">Financial Stability</div>
                    <div className={`metric-card__value ${financialStrengthPercentage >= 60 ? 'green' : 'red'}`}>
                        {Math.round(financialStrengthPercentage)}%
                    </div>
                </div>

                <div className="metric-card">
                    <div className="metric-card__label">FMP Recommendation</div>
                    <div className="metric-card__value neutral">
                        {ratingRecommendation || 'Neutral'}
                    </div>
                </div>

                <div className="metric-card">
                    <div className="metric-card__label">Buy Timing Indicator</div>
                    <div className={`metric-card__value ${purchaseAnalysis.scoreScaled >= 60 ? 'green' : 'red'}`}>
                        {Math.round(purchaseAnalysis.scoreScaled)}%
                    </div>
                </div>
            </div>
        </div>
    );
};