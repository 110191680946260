import { GenericError, useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getStockDetails, getStockPriceChange, getStockSummary } from '../../../services/stock.service';
import { FinancialSummary, StockPriceChange, YearlyScore } from 'src/models/stock-types';
import { LoadingDotText } from '../../Loading/LoadingDotsText';
import { StockFinancialSummary } from './StockFinancialSummary';
import StockDetailChart from './StockDetailChart';
import { StockDetail } from './StockDetail';
import StockPriceChangeChart from './StockPriceChangeChart';
import Divider from 'src/components/Divider/Divider';
import GenericErrorMessage from 'src/components/Error/GenericErrorMessage';

interface StockDetailProps {
  ticker: string;
}

export const StockDetails: React.FC<StockDetailProps> = ({ ticker }) => {
  const [yearlyScores, setYearlyScores] = useState<YearlyScore[]>([]);
  const [financialSummary, setFinancialSummary] = useState<FinancialSummary>();
  const [stockPriceChange, setStockPriceChange] = useState<StockPriceChange>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [yearRange, setYearRange] = useState(4);
  const { getAccessTokenSilently } = useAuth0();
  const [isApiUnavailable, setIsApiUnavailable] = useState(false);
  const [showChart, setShowChart] = useState(false); // State to toggle between chart and list view

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      setIsLoading(true);
      setYearlyScores([]);
      setError(null);

      const yearlyScoreResponse = await getStockDetails(accessToken, ticker, yearRange);
      const financialSummaryResponse = await getStockSummary(accessToken, ticker);
      const stockPriceChangeResponse = await getStockPriceChange(accessToken, ticker); 
      if (yearlyScoreResponse.data === undefined) {
        setYearlyScores([]);
        setError('No data available for the given ticker and year range.');
        setIsLoading(false);
        return;
      }
      if (financialSummaryResponse.data === null) {
        setFinancialSummary(undefined);
        setError('No data available for the given ticker and year range.');
        setIsLoading(false);
      }
      if (stockPriceChangeResponse.data === null) {
        setStockPriceChange(undefined);
        setError('No stock price change data available for the given ticker and year range.');
        setIsLoading(false);
      }

      if (stockPriceChangeResponse.data) {
        setStockPriceChange(stockPriceChangeResponse.data);
      }

      if (yearlyScoreResponse.data) {
        setYearlyScores(yearlyScoreResponse.data);
      }

      if (financialSummaryResponse.data) {
        setFinancialSummary(financialSummaryResponse.data);
      }

      if (yearlyScoreResponse.error || financialSummaryResponse.error) {
        setError('Error retrieving financial data.');
      }

      setIsLoading(false);
    };

    fetchData();
  }, [ticker, yearRange, getAccessTokenSilently]);

  return (
    <div className="stock-details">
      {isApiUnavailable && (
        <p className="api-status-message">
          The API is temporarily unavailable. Please try again later.
        </p>
      )}
      
      {isLoading ? (
        <div>
          <h3 className="stock-details__title">
            <LoadingDotText text={`Loading data for ${ticker}`} />
          </h3>
        </div>
      ) : (
        <div>
          <div>
            <label className="section-header">
              Overview
            </label>
          </div>
          {error && <GenericErrorMessage message={error} onClose={() => setError(null)} />}
          {financialSummary && (
            <StockFinancialSummary financialSummary={financialSummary} />
          )}
          <Divider />
                <h3 className="section-header">Price Changes</h3>
                <div className="price-change-grid">
                    {[
                        { period: '5 Years', value: '248%', isPositive: true },
                        { period: '3 Years', value: '27%', isPositive: true },
                        { period: '1 Year', value: '30%', isPositive: true },
                        { period: 'Year to Date', value: '-9%', isPositive: false },
                        { period: '6 Months', value: '-5%', isPositive: false },
                        { period: '3 Months', value: '-12%', isPositive: false },
                        { period: '1 Month', value: '-9%', isPositive: false },
                        { period: '5 Days', value: '-1%', isPositive: false },
                        { period: '1 Day', value: '2%', isPositive: true }
                    ].map(item => (
                        <div key={item.period} className="price-change-card">
                            <div className="price-change-card__period">{item.period}</div>
                            <div className={`price-change-card__value ${item.isPositive ? 'positive' : 'negative'}`}>
                                {item.isPositive ? '↑' : '↓'} {item.value}
                            </div>
                        </div>
                    ))}
                </div>
          <Divider />
          <div>
            <label className="section-header">
              Indicator Overview
            </label>
          </div>
          <button className="toggle-button" onClick={() => setShowChart(!showChart)}>
            {showChart ? 'List View' : 'Chart View'}
          </button>
          <div >
            {showChart ? (
              <StockDetailChart yearlyScores={yearlyScores} />
            ) : (
              <>
                <div className="select-container">
                  <label className="select-label-quarters" htmlFor="yearRange">
                    Number of quarters
                  </label>
                  <select
                    className="custom-select"
                    value={yearRange}
                    onChange={(e) => setYearRange(Number(e.target.value))}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="stock-details__grid">
                {yearlyScores.slice().reverse().map((score, index) => (
                  <StockDetail
                    key={`${score.year}-${score.quarter}`}
                    year={score.year}
                    quarter={score.quarter}
                    yearScore={score}
                    previousYearScore={yearlyScores.slice().reverse()[index + 1]}
                  />
                ))}
                </div>
                <Divider />
                <h3 className="section-header">Technical Indicators</h3>
                <div className="indicators-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Indicator</th>
                                <th>Value</th>
                                <th>Average</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>SMA</td>
                                <td>50d</td>
                                <td>{Math.round(73.02)}</td>
                            </tr>
                            <tr>
                                <td>EMA</td>
                                <td>20d</td>
                                <td>{Math.round(66.66)}</td>
                            </tr>
                            <tr>
                                <td>RSI</td>
                                <td>14d</td>
                                <td>{Math.round(94.89)}</td>
                            </tr>
                            <tr>
                                <td>ADX</td>
                                <td>14d</td>
                                <td>{Math.round(121.13)}</td>
                            </tr>
                            <tr>
                                <td>W%R</td>
                                <td>14d</td>
                                <td>{Math.round(-22.07)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};