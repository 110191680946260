import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { PageLayout } from '../components/PageLayout';
import { getUserDetails, UserDetails, SubscriptionPlan, SubscriptionStatus } from '../services/user.service';
import { Link } from 'react-router-dom';

const getPlanDisplayName = (plan: SubscriptionPlan): string => {
	switch (plan) {
		case 'none':
			return 'Free';
		case 'starter':
			return 'Basic';
		case 'pro':
			return 'Pro';
		case 'premium':
			return 'Premium';
		default:
			return 'Unknown';
	}
};

const getStatusDisplayName = (status: SubscriptionStatus): string => {
	return status.charAt(0).toUpperCase() + status.slice(1);
};

const getStatusColor = (status: SubscriptionStatus): string => {
	switch (status) {
		case 'active':
			return 'success';
		case 'inactive':
			return 'warning';
		case 'canceled':
			return 'error';
		default:
			return 'default';
	}
};

export const ProfilePage: React.FC = () => {
	const { user, getAccessTokenSilently } = useAuth0();
	const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchUserDetails = async () => {
			try {
				const accessToken = await getAccessTokenSilently();
				const { data } = await getUserDetails(accessToken, user?.sub || '');
				if (data) {
					setUserDetails(data);
				}
			} catch (error) {
				console.error('Error fetching user details:', error);
			} finally {
				setLoading(false);
			}
		};

		if (user) {
			fetchUserDetails();
		}
	}, [user, getAccessTokenSilently]);

	if (!user) {
		return null;
	}

	return (
		<PageLayout>
			<div className="content-layout">
				<h1 id="page-title" className="content__title profile-page__title">
					Profile Page
				</h1>
				<div className="profile-page__container">
					<div className="profile-page__header">
						<div className="profile-page__avatar-container">
							{user.picture ? (
								<img
									src={user.picture}
									alt="Profile"
									className="profile-page__avatar"
								/>
							) : (
								<div className="profile-page__avatar-placeholder">
									{user.name?.[0] || 'U'}
								</div>
							)}
						</div>
						<div className="profile-page__user-info">
							<h2 className="profile-page__name">{user.name}</h2>
							<p className="profile-page__email">{user.email}</p>
						</div>
					</div>

					<div className="profile-page__content">
						<div className="profile-page__section">
							<h3 className="profile-page__section-title">Subscription Details</h3>
							<div className="profile-page__subscription-info">
								<div className="profile-page__info-item">
									<span className="profile-page__info-label">Current Plan</span>
									<span className={`profile-page__info-value profile-page__info-value--plan`}>
										{loading ? 'Loading...' : getPlanDisplayName(userDetails?.subscriptionPlan || 'none')}
									</span>
								</div>
								<div className="profile-page__info-item">
									<span className="profile-page__info-label">Status</span>
									<span className={`profile-page__info-value profile-page__info-value--${getStatusColor(userDetails?.subscriptionStatus || 'inactive')}`}>
										{loading ? 'Loading...' : getStatusDisplayName(userDetails?.subscriptionStatus || 'inactive')}
									</span>
								</div>
								{!loading && userDetails?.subscriptionPlan !== 'premium' && (
									<Link to="/subscription" className="profile-page__upgrade-link">
										Upgrade Your Plan
									</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};
