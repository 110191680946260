import React, { useEffect, useState } from 'react';
import { PageLayout } from '../../components/PageLayout';
import { unsubscribeUser } from 'src/services/newsletter.service';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from 'src/components/Loading/LoadingSpinner';

export const NewsletterUnsubscribePage: React.FC = () => {
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const routeParams = useParams();
    const { email } = routeParams;

    useEffect(() => {
        if (email) {
            const unsubscribeEmail = async () => {
                try {
                    const { data, error } = await unsubscribeUser({ email });
                    if (error) {
                        setError('There was an error unsubscribing your email.');
                    } else {
                        setMessage('You have been successfully unsubscribed from our newsletter.');
                    }
                } catch (err) {
                    setError('There was an error unsubscribing your email.');
                } finally {
                    setIsLoading(false);
                    setTimeout(() => {
                        navigate("/", { replace: true });
                    }, 5000);
                }
            };
            unsubscribeEmail();
        } else {
            setError('Invalid unsubscription link.');
            setIsLoading(false);
        }
    }, [email, navigate]);

    return (
        <PageLayout>
            <div className="newsletter-unsubscribe">
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <h2 className="stock-filings__title">
                            {error ? 'Error' : 'Newsletter Unsubscription'}
                        </h2>
                        <p className="hero-banner__headline__3">
                            {error || message}
                        </p>
                    </div>
                )}
            </div>
        </PageLayout>
    );
};