import React from 'react';
import { PageLayout } from '../../components/PageLayout';

export const ApiAccessPage: React.FC = () => {
    return (
        <PageLayout>
            <div className="content-layout">
                <h1 id="page-title" className="content__title">
                    Custom API Access
                </h1>
                <div className="content__body">
                    <section className="feature-section">
                        <h2>Premium API Integration</h2>
                        <p>
                            Access our comprehensive stock market data and analysis tools through our powerful API. 
                            Perfect for developers, financial institutions, and advanced traders who need programmatic 
                            access to our platform's capabilities.
                        </p>
                    </section>

                    <section className="feature-section">
                        <h2>API Features</h2>
                        <ul>
                            <li>Real-time market data streaming</li>
                            <li>Historical data access</li>
                            <li>Advanced analytics endpoints</li>
                            <li>Custom data aggregation</li>
                            <li>High-frequency trading support</li>
                        </ul>
                    </section>

                    <section className="feature-section">
                        <h2>Integration Options</h2>
                        <p>
                            Our API supports multiple integration methods:
                        </p>
                        <ul>
                            <li>RESTful API endpoints</li>
                            <li>WebSocket connections</li>
                            <li>GraphQL interface</li>
                            <li>SDK support for major languages</li>
                        </ul>
                    </section>

                    <section className="feature-section">
                        <h2>Premium Benefits</h2>
                        <ul>
                            <li>Higher rate limits</li>
                            <li>Priority support</li>
                            <li>Custom data feeds</li>
                            <li>Dedicated account manager</li>
                            <li>Advanced security features</li>
                        </ul>
                    </section>

                    <section className="feature-section">
                        <h2>Getting Started</h2>
                        <p>
                            To access our API:
                        </p>
                        <ol>
                            <li>Upgrade to our Premium plan</li>
                            <li>Generate your API keys</li>
                            <li>Review our comprehensive documentation</li>
                            <li>Start integrating with our endpoints</li>
                        </ol>
                    </section>
                </div>
            </div>
        </PageLayout>
    );
};

export default ApiAccessPage; 