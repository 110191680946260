import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { StockNavBarTab } from './StockNavBarTab';

interface StockNavBarTabsProps {
	stockTicker: string | undefined;
}

export const StockNavBarTabs: React.FC<StockNavBarTabsProps> = ({
	stockTicker,
}) => {
	const { isAuthenticated } = useAuth0();

	return (
		<div className="stock__nav-bar__tabs">
			{isAuthenticated && (
				<>
					<StockNavBarTab
						path={`/stockDetails/${stockTicker}/financialData`}
						label="Stock Analysis"
					/>
					{/* <StockNavBarTab
						path={`/stockDetails/${stockTicker}/aiAnalysis`}
						label="AI Analysis"
					/> */}
					{/* <StockNavBarTab
						path={`/stockDetails/${stockTicker}/marketAnalysis`}
						label="Market Analysis"
					/> */}
				</>
			)}
		</div>
	);
};
