import { Link } from 'react-router-dom';
import { FinancialSummary, Stock } from 'src/models/stock-types';

interface StockFilingCardProps extends Stock {
    href: string;
    position: number;
}

export const StockFilingCard: React.FC<StockFilingCardProps> = ({
    id,
    filingDate,
    tradeDate,
    ticker,
    companyName,
    industry,
    numberOfInsiderTraders,
    tradeType,
    purchasePrice,
    quantity,
    previouslyOwned,
    percentageOwned,
    purchaseTotal,
    ownershipIncrease,
    insiderFilingScore,
    latestFinancialScore,
    trendAdjustedScore,
    financialStrengthPercentage,
    rating,
    ratingScore,
    recommendation,
    isBuySignal,
    rawScore,
    scoreScaled,
    explanation,
    href,
    position,
    priceChangeSinceFilingDate,
    mlPriceIncreaseProbability
}) => {
    const decodeHtmlEntities = (str: string) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
    };
    const decodedTitle = decodeHtmlEntities(companyName);

    const getClassForValue = (value: number, max: number = 100) => {
        const percentage = (value / max) * 100;
        if (percentage >= 0 && percentage < 30 || percentage < 0) {
            return 'red-metric';
        } else if (percentage >= 30 && percentage < 60) {
            return 'orange-metric';
        } else if (percentage >= 60 && percentage <= 100) {
            return 'green-metric';
        }
        return '';
    };

    return (
        <div className="stockFilingCard-container">
            <Link to={href} className="stockFilingCard-link">
                <div className="stockFilingCard-content">
                    {/* Header Section */}
                    <div className="stockFilingCard-header-section">
                        <div className="stockFilingCard-title-row">
                            <span className="stockFilingCard-position">{position}</span>
                            <h2 className="stockFilingCard-title">{decodedTitle}</h2>
                        </div>
                        <div className="stockFilingCard-meta">
                            <span className="stockFilingCard-ticker">{ticker}</span>
                            <span className="stockFilingCard-date">{tradeDate}</span>
                        </div>
                        <p className="stockFilingCard-industry">{industry}</p>
                    </div>

                    {/* Scores Section */}
                    <div className="stockFilingCard-scores-section">
                        <div className="stockFilingCard-score-group">
                            <div className="stockFilingCard-score-item">
                                <span className="score-label">Filing Score</span>
                                <span className={`score-value ${getClassForValue(insiderFilingScore)}`}>
                                    {Math.round(insiderFilingScore)}%
                                </span>
                            </div>
                            <div className="stockFilingCard-score-item">
                                <span className="score-label">JDK Score</span>
                                <span className={`score-value ${getClassForValue(trendAdjustedScore)}`}>
                                    {Math.round(trendAdjustedScore)}%
                                </span>
                            </div>
                            <div className="stockFilingCard-score-item">
                                <span className="score-label">FMP Score</span>
                                <span className={`score-value ${getClassForValue(ratingScore, 5)}`}>
                                    {Math.round((ratingScore / 5) * 100)}%
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Metrics Section */}
                    <div className="stockFilingCard-metrics-section">
                        <div className="stockFilingCard-metric-group">
                            <div className="metric-item">
                                <span className="metric-label">Financial Stability</span>
                                <span className={`metric-value ${getClassForValue(financialStrengthPercentage)}`}>
                                    {financialStrengthPercentage}%
                                </span>
                            </div>
                            <div className="metric-item">
                                <span className="metric-label">Buy Timing</span>
                                <span className={`metric-value ${getClassForValue(scoreScaled)}`}>
                                    {Math.round(scoreScaled)}%
                                </span>
                            </div>
                            <div className="metric-item">
                                <span className="metric-label">ML Probability</span>
                                <span className={`metric-value ${getClassForValue(mlPriceIncreaseProbability || 0)}`}>
                                    {mlPriceIncreaseProbability !== undefined ? Math.round(mlPriceIncreaseProbability * 100) : 'N/A'}%
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Footer Section */}
                    <div className="stockFilingCard-footer">
                        <div className="footer-item">
                            <span className="footer-label">Recommendation</span>
                            <span className={`footer-value recommendation ${recommendation?.toLowerCase() || 'neutral'}`}>
                                {recommendation || 'Neutral'}
                            </span>
                        </div>
                        <div className="footer-item">
                            <span className="footer-label">Price Change</span>
                            <span className={`footer-value ${Number(priceChangeSinceFilingDate) >= 0 ? 'positive' : 'negative'}`}>
                                {priceChangeSinceFilingDate}
                            </span>
                        </div>
                        <div className="footer-item">
                            <span className="footer-label">Insiders</span>
                            <span className="footer-value">{numberOfInsiderTraders}</span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};
